<!--
 * @Author: yy
 * @Date: 2021-09-28 16:44:30
 * @LastEditTime: 2021-12-22 14:49:21
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \cat_student\src\components\academicArchives\recordTables.vue
-->
<template>
  <div class="tableView">
    <div class="item fixed" :class="{ type0: type == 0 }">
      <span>日期</span>
      <span style="flex:1.5">专题</span>
      <span>分数</span>
      <span>用时</span>
      <span style="flex: 0.2"></span>
    </div>
    <div style="height: 60px" v-if="type != 0"></div>
    <div v-for="(item, index) in list" :key="index" @click="toCatReport(item.testId)" class="item">
      <span>{{ getTime(item.creatTime) }}</span>
      <span style="flex:1.5">{{ item.catName }}</span>
      <span class="fraction">{{ item.score }}</span>
      <span>
        <template
          v-if="item.costTime<$store.state.catTesting.totalTime*60"
        >{{item.costTime|formatSeconds}}</template>
        <template v-else>{{$store.state.catTesting.totalTime*60|formatSeconds}}</template>
      </span>
      <span style="flex: 0.2">
        <v-icon>icon-arrow-right</v-icon>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: Array,
    type: Number,
  },
  data() {
    return {}
  },
  methods: {
    getTime(creatTime) {
      let time = new Date(parseInt(creatTime) * 1000)
      let T = time.getFullYear() + '-'
      let M =
        (time.getMonth() + 1 < 10
          ? '0' + (time.getMonth() + 1)
          : time.getMonth() + 1) + '-'
      let D = time.getDate() + ' '
      let h = time.getHours() + ':'
      let m =
        time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes()
      return T + M + D + h + m
    },
    toCatReport(id) {
      this.$router.push({
        name: 'CatReport',
        query: {
          id: id,
          path: 'academicArchives',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.tableView {
  padding: 0 30px 20px;
  .item {
    display: flex;
    align-items: center;
    height: 70px;
    width: 100%;
    background: #fff;
    border-bottom: 1px solid rgba(238, 238, 238, 0.5);
    color: #666;

    span {
      flex: 1;
      background: #fff;
      font-family: auto;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .fraction {
      color: #333;
      font-weight: bold;
      font-size: 24px;
      font-family: Akrobat-Black !important;
    }
  }

  .fixed {
    width: calc(100vw - 112px);
    color: #999;
    font-size: 14px;
    position: fixed;
  }
  .type0 {
    position: relative;
    width: 100%;
  }
  .v-icon.v-icon {
    position: initial;
  }
}
</style>
