<!--
 * @Author: yy
 * @Date: 2021-09-26 17:24:20
 * @LastEditTime: 2021-10-15 17:26:05
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \cat_student\src\components\echarts\treemap.vue
-->
<template>
  <div>
    <div id="treemap" class="test" :style="$store.state.mini?'width:calc(100vw - 168px)':''"></div>
  </div>
</template>

<script>
export default {
  props: {
    isReset: Boolean,
  },
  created() {
    this.getMyEcharts()
  },
  computed: {
    changeMini() {
      return this.$store.state.mini
    },
  },
  watch: {
    isReset: {
      handler: function (newObj, oldObj) {
        if (newObj && !oldObj) {
          this.onReset()
        }
      },
      deep: true,
    },
    changeMini() {
      let that = this
      setTimeout(function () {
        that.onReset()
      }, 50)
    },
  },
  data: () => ({
    treeMapData: [],
    myChart: '',
    selectDataIndex: '',
  }),
  methods: {
    // initEcharts() {
    //   let newPromise = new Promise(resolve => {
    //     resolve();
    //   });
    //   newPromise.then(() => {
    //     this.drawMyChart();
    //   });
    // },
    getMyEcharts() {
      this.$service.getTreeMap().then((res) => {
        let fraction0 = [] //0
        let fraction60 = [] //0-60
        let fraction80 = [] //60-80
        let fraction100 = [] //80-100

        let area0 = 0
        let area60 = 0
        let area80 = 0
        let area100 = 0
        res.abilityMap.map((r) => {
          r.value = [r.weight, r.weight, r.score]
          r.name = r.label
          if (r.score == 0) {
            fraction0.push({
              value: [r.weight, r.weight, r.score],
              name: r.label,
            })
            area0 = area0 + r.weight
          } else if (r.score < 60) {
            fraction60.push({
              value: [r.weight, r.weight, r.score],
              name: r.label,
            })
            area60 = area60 + r.weight
          } else if (r.score < 80) {
            fraction80.push({
              value: [r.weight, r.weight, r.score],
              name: r.label,
            })
            area80 = area80 + r.weight
          } else {
            fraction100.push({
              value: [r.weight, r.weight, r.score],
              name: r.label,
            })
            area100 = area100 + r.weight
          }
          return r
        })
        if (fraction0 && fraction0.length) {
          this.treeMapData.push({
            value: [area0, null, null],
            id: 'fraction0',
            children: fraction0,
            itemStyle: { color: '#8d8d8d' },
          })
        }
        if (fraction60 && fraction60.length) {
          this.treeMapData.push({
            value: [area60, null, null],
            id: 'fraction60',
            children: fraction60,
            itemStyle: { color: '#FF6262' },
          })
        }
        if (fraction80 && fraction80.length) {
          this.treeMapData.push({
            value: [area80, null, null],
            id: 'fraction80',
            children: fraction80,
            itemStyle: { color: '#FFB13F' },
          })
        }
        if (fraction100 && fraction100.length) {
          this.treeMapData.push({
            value: [area100, null, null],
            id: 'fraction100',
            children: fraction100,
            itemStyle: { color: '#00CE92' },
          })
        }
        this.drawMyChart()
      })
    },
    onReset() {
      this.myChart.resize()
      this.selectDataIndex = ''
      this.$emit('isReseted')
    },
    drawMyChart() {
      var that = this
      this.myChart = this.echarts.init(document.getElementById('treemap'))
      this.myChart.showLoading()
      var option
      this.myChart.hideLoading()
      const visualMin = 0
      const visualMax = 1
      const visualMinBound = 0
      const visualMaxBound = 0
      convertData(this.treeMapData)

      function convertData(originList) {
        let min = Infinity
        let max = -Infinity
        for (let i = 0; i < originList.length; i++) {
          let node = originList[i]
          if (node) {
            let value = node.value
            value[2] != null && value[2] < min && (min = value[2])
            value[2] != null && value[2] > max && (max = value[2])
          }
        }
        for (let i = 0; i < originList.length; i++) {
          let node = originList[i]
          if (node) {
            let value = node.value
            if (value[2] != null && value[2] > 0) {
              value[3] = that.echarts.number.linearMap(
                value[2],
                [0, max],
                [visualMaxBound, visualMax],
                true
              )
            } else if (value[2] != null && value[2] < 0) {
              value[3] = that.echarts.number.linearMap(
                value[2],
                [min, 0],
                [visualMin, visualMinBound],
                true
              )
            } else {
              value[3] = 0
            }
            if (!isFinite(value[3])) {
              value[3] = 0
            }
            if (node.children) {
              convertData(node.children)
            }
          }
        }
      }
      function isValidNumber(num) {
        return num != null && isFinite(num)
      }
      // this.myChart.on("click", function(params) {
      //   if (that.selectDataIndex == params.dataIndex) {
      //     that.$router.push({
      //       name: "AdaptiveTesting"
      //     });
      //   } else {
      //     that.selectDataIndex = params.dataIndex;
      //   }
      // });
      this.myChart.setOption(
        (option = {
          tooltip: {
            formatter: function (info) {
              let value = info.value
              let change = value[2]
              change = isValidNumber(change) ? change.toFixed(2) : '-'
              return [
                '<div class="tooltip-title">' +
                  that.echarts.format.encodeHTML(info.name) +
                  '</div>',
                '<div>成绩: &nbsp;&nbsp;' + change + '</div>',
                '高考分值: &nbsp;&nbsp;' + value[0],
              ].join('')
            },
          },
          series: [
            {
              name: 'ALL',
              // top: 110,
              width: '100%',
              height: '100%',
              // left:'20%',
              type: 'treemap',
              roam: 'true', //不能缩放平移
              // nodeClick: "zoomToNode", //节点点击无反应。
              zoomToNodeRatio: 0.52 * 0.52, //点击某个节点，会自动放大那个节点到合适的比例（节点占可视区域的面积比例）
              label: {
                show: true,
                formatter: '{b}',
                ellipsis: true,
                fontSize: 20,
              },
              breadcrumb: {
                show: false, //是否显示面包屑
              },
              itemStyle: {
                borderWidth: 1,
                borderColor: '#fff',
              },
              visualMin: visualMin,
              visualMax: visualMax,
              visualDimension: 3,
              levels: [
                {
                  itemStyle: {
                    borderWidth: 0,
                    borderColor: '#333',
                    gapWidth: 0,
                  },
                },
                {
                  colorMappingBy: 'value',
                  itemStyle: {
                    borderWidth: 0,
                    gapWidth: 0,
                  },
                },
              ],
              tooltip: {
                textStyle: {
                  fontSize: 24,
                },
              },
              data: this.treeMapData,
            },
          ],
        })
      )
      // }
      option && this.myChart.setOption(option)
    },
  },
}
</script>
<style scoped>
.test {
  width: calc(100vw - 330px);
  height: calc(100vh - 96px);
  padding: 30px 40px;
}
</style>
