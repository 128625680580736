<!--
 * @Author: yy
 * @Date: 2021-09-27 11:11:07
 * @LastEditTime: 2021-10-12 10:32:24
 * @LastEditors: yy
 * @Description: 
 * @FilePath: \cat_student\src\components\echarts\linemap.vue
-->
 
<template>
  <div>
    <div :id="idNumber" v-if="show" :class="(path == 'academicArchives')&& $store.state.mini? 'academicArchives academicArchives-mini' : path"></div>
  </div>
</template>

<script>
export default {
  computed: {
    changeMini() {
      return this.$store.state.mini
    },
    getMapData() {
      return this.mapData
    },
  },
  watch: {
    changeMini() {
      if (this.path == 'academicArchives') {
        let that = this
        setTimeout(function () {
          that.myChart.resize()
        }, 50)
      }
    },
    getMapData() {
      if (this.mapData) {
        this.show = true
        this.initEcharts()
      } else {
        this.show = false
      }
    },
  },
  props: {
    path: String,
    idNumber: String,
    mapData: Array,
  },
  created() {
    if (this.path == 'adaptiveTesting') {
      this.initEcharts()
    }
  },
  data() {
    return {
      show: true,
      xAxisData: [], //x轴
      yData: [],
    }
  },
  methods: {
    initEcharts() {
      let newPromise = new Promise((resolve) => {
        resolve()
      })
      newPromise.then(() => {
        this.getMyEcharts()
      })
    },
    getMyEcharts() {
      if (this.path == 'adaptiveTesting' || this.path == 'adaptiveTestingDetail' || this.path == 'academicArchives') {
        this.xAxisData = this.mapData.map((res) => {
          let time = new Date(parseInt(res.time) * 1000)
          let M =(time.getMonth() + 1 < 10 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1) + '-'
          let D = time.getDate() + '\n';
          let h = time.getHours() + ':';
          let m = time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes();
          return M + D + h + m;
        })
        this.yData = this.mapData.map((res) => {
          return res.score
        })
      }
      this.drawMyChart()
    },
    drawMyChart() {
      this.myChart = this.echarts.init(document.getElementById(this.idNumber))
      var option
      option = {
        xAxis: {
          type: 'category',
          data: this.xAxisData,
          show: this.path == 'adaptiveTesting' ? false : true,
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            margin: 20,
            color: '#999',
            fontSize: 14,
             fontFamily: " PingFangSC-Normal",
             lineHeight: 16,
          },
        },
        yAxis: {
          type: 'value',
          show: this.path == 'adaptiveTesting' ? false : true,
          offset: 20,
          max: 100,
          axisLabel: {
            margin: this.path == 'adaptiveTestingDetail' ? -5 : 20,
            color: '#999',
            fontSize: 13,
          },
        },
        series: [
          {
            data: this.yData,
            type: 'line',
            smooth: true,
            symbolSize: 0, //标记点的大小
            lineStyle: {
              color: '#2F2F39',
              width: 3,
              shadowColor: 'rgba(0, 0, 0, 0)',
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowOffsetY: 10,
            },
            endLabel: {
              show: true, //是否显示折线端点的标签
              distance: 0, //距离图形元素的距离
              offset: [-20, -30], //对文字进行偏移
              fontWeight: 'bold',
              fontSize: 15,
              backgroundColor: '#ffe900',
              padding: [0, 10],
              lineHeight: 20,
              borderRadius: 10,
              shadowColor: 'rgba(0, 0, 0, 0.22)',
              shadowBlur: 10,
              shadowOffsetX: 4,
              shadowOffsetY: 4,
            },
            markPoint: {
              data: [
                {
                  type: 'max',
                  valueIndex: 0,
                  symbolSize: 18,
                },
              ],
              symbol: 'circle',
              symbolOffset: [0, 0],
              label: {
                show: false,
              },
              itemStyle: {
                color: '#ffe900',
                shadowColor: 'rgba(0, 0, 0, 0.22)',
                shadowBlur: 10,
                shadowOffsetX: 4,
                shadowOffsetY: 4,
              },
            },
          },
        ],
      }
      option && this.myChart.setOption(option)
    },
  },
}
</script>
<style scoped lang="scss">
.academicArchives {
  width: calc(100vw - 330px);
  height: calc(100vh - 246px);
  padding: 0 20px;
}
.academicArchives-mini {
  width: calc(100vw - 168px);
}
.adaptiveTesting {
  width: 200px;
  height: 230px;
  padding: 0;
  margin-top: -30px;
}
.adaptiveTestingDetail {
  width: 380px;
  height: 280px;
  padding: 0;
}
</style>
