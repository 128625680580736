<!--
 * @Author: yy
 * @Date: 2021-09-26 17:05:08
 * @LastEditTime: 2022-04-14 15:22:03
 * @LastEditors: yy
 * @Description: 
 * @FilePath: \cat_student\src\views\academicArchives\index.vue
-->
<template>
  <el-container class="adaptive-testing">
    <el-header>
      <public-header :leftArrow="false">
        <span>学情档案</span>
        <span class="resetBtn" @click="onReset">重置</span>
      </public-header>
    </el-header>
    <el-main>
      <!-- <loadMore ref="more" > -->
      <div class="containerView">
        <v-card class="rounded-xl" style="margin-bottom: 40px">
          <treemap :isReset="isReset" @isReseted="isReseted" />
        </v-card>

        <v-card class="rounded-xl" style="margin-bottom: 40px;min-height:180px">
          <colorTitle class="colorTitle">专题能力评估</colorTitle>
          <div class="lineTreeContent">
            <div class="score">
              <div>
                <p>{{ score || "--" }}</p>
                <p>近期能力评估</p>
              </div>
              <div>
                <p class="ranking" v-if="schoolRanking">
                  前
                  <span>{{ schoolRanking }}%</span>
                </p>
                <p v-else>--</p>
                <p>全校排名</p>
              </div>
            </div>
            <select
              class="select"
              v-model="selectId"
              @change="getTopicArchive"
              :value="selectValue"
            >
              <option v-for="item in selectList" :key="item.id" :value="item.id">{{ item.label }}</option>
            </select>
          </div>
          <linemap idNumber="linemap" path="academicArchives" :mapData="lineMapData" />
        </v-card>

        <v-card class="rounded-xl">
          <colorTitle class="colorTitle">
            <span>自适应测试记录</span>
            <span class="readMore" @click="toRecordList">
              查看更多
              <v-icon>icon-arrow-right</v-icon>
            </span>
          </colorTitle>
          <recordTables :list="recordList" :type="0" />
          <div v-if="!(recordList&&recordList.length)" class="noDataTips">暂无评估数据</div>
        </v-card>
      </div>
      <!-- </loadMore> -->
    </el-main>
  </el-container>
</template>
<script>
import treemap from '@/components/echarts/treemap'
import colorTitle from '@/components/public/colorTitle'
import linemap from '@/components/echarts/linemap'
import recordTables from '@/components/academicArchives/recordTables'
// import loadMore from '@/components/loadMore/loadMore'

export default {
  components: {
    treemap,
    colorTitle,
    linemap,
    recordTables,
    // loadMore
  },
  data: () => ({
    isReset: false,
    treeMapData: [],
    selectId: '', //选中的id
    selectList: [], //专题能力评估下拉选择列表
    selectValue: '',
    lineMapData: [], //专题能力评估数据
    score: 0, //分数
    schoolRanking: '', //学校排名
    recordList: [], //自适应测试记录
  }),
  mounted() {
    this.getTopicTitleList()

    this.getUserCatRecord()
  },
  methods: {
    //获取专题能力评估下拉选择列表
    getTopicTitleList() {
      this.$service.getTopicTitleList().then((res) => {
        // 节选
        this.selectList = res.list.list
        this.selectId = this.selectList[0].id
        this.getTopicArchive()
      })
    },
    //获取专题能力评估数据
    getTopicArchive() {
      this.$service
        .getTopicArchive({
          topicId: this.selectId,
        })
        .then((res) => {
          if (res.records) {
            this.lineMapData = res.records
          } else {
            this.lineMapData = res.records
          }
          if (res.records && res.records.length) {
            this.score = res.records[res.records.length - 1].score + ''
          } else {
            this.score = ''
          }
          this.schoolRanking = Math.ceil((res.allRank / res.allRankNum) * 100)
        })
    },
    //获取自适应测试记录列表
    getUserCatRecord() {
      this.$service
        .getUserCatRecord({
          pageNum: 1,
          pageSize: 5,
        })
        .then((res) => {
          this.recordList = res.catRecords
        })
    },
    onReset() {
      this.isReset = true
    },
    isReseted() {
      this.isReset = false
    },
    toRecordList() {
      this.$router.push({
        path: 'RecordList',
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.el-main {
  padding-right: 0 !important;
}
.containerView {
  height: 100%;
  overflow-y: scroll;
  padding-right: 32px;
  overflow-x: hidden;
}
.resetBtn {
  display: block;
  float: right;
  font-size: 30px;
}

.colorTitle {
  padding: 20px 0 0 30px;
  height: 50px;
}
.lineTreeContent {
  height: 80px;
  padding-top: 20px;
  padding-left: 10px;
  .score {
    display: flex;
    width: 320px;
    float: left;
    div {
      flex: 1;
      text-align: center;
      p:first-of-type {
        margin: 0;
        font-size: 50px;
        line-height: 60px;
        height: 60px;
        font-family: Akrobat-Black;
        color: #333;
      }
      p:nth-of-type(2) {
        margin: 0;
        font-size: 18px;
        color: #999;
      }
      .ranking {
        font-size: 20px !important;
        font-family: Akrobat-Semibold !important;
        span {
          font-size: 30px;
        }
      }
    }
  }
  .select {
    overflow: hidden !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 43px;
    width: 200px;
    height: 40px !important;
    float: right;
    margin-right: 30px;
    background: #f0f0f0 url('~@/assets/images/table/select.png') no-repeat;
    background-size: 26px 26px;
    background-position: 165px 7px;
    border-radius: 8px;
    padding-left: 15px;
    color: #666;
    border-width: 0 !important ;
  }
}
.readMore {
  color: #999;
  display: block;
  float: right;
  margin: 0 30px 20px 0;
  font-size: 16px;
  font-weight: 300;
  font-family: auto;
  line-height: 24px;
}
.noDataTips {
  color: #999;
  clear: both;
  text-align: center;
  margin-top: 10px;
  height: 50px;
  font-size: 24px;
}
</style>
